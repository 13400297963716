.container-nav {
    color: red;
    justify-content: flex-end;
}
/* .navlinks {
    justify-content: flex-end;
} */
.company-name {
    margin-left: 10px;
    color: #a41e25;
}

.nav-text {
    color: #a41e25;
    justify-content: end;
    margin-right: 10px;
}
img {
    height: 40px;
}
