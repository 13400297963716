div.card-body {
    margin: 7px;
    /* text-align: center; */
}
.cards {
    display: flex;
    background-color: gray;
}
.img-fluid {
    height: 600px;
    width: 100%;
}
.bottom-card {
    text-align: center;
    border-color: black;
    border: 1px solid #000000;
    color: white;
}
p {
    margin-top: revert;
}
.container-flex {
    padding-bottom: 10px;
}
.line {
    border-bottom: 2px solid gray;
}

.top-card-cont {
    margin-bottom: 30px;
}

/* Card.Title {
    padding: 20px;
}
#card.text {
    padding: 40px;
} */
.jumbotron {
    background-image: url("https://imgur.com/a/fM1NA1C");
    background-size: cover;
    height: 70vh;
    text-align: center;

    margin-bottom: 0px;
}
h6 {
    text-align: end;
    margin-right: 15px;
}
