.right {
    justify-content: right;
}
.mission {
    display: flex;
    padding-bottom: 10px;
}

li {
    padding: 10px;
}
.mission-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .container-xl {
    display: contents;
    padding: 5px;
} */

ul {
    list-style: none;
}
ul {
    list-style: none;
}

li::before {
    content: " • ";
    color: #a41e25;
}
