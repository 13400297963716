.card-main {
    padding-bottom: 10px;
}

.title {
    text-align: center;
}
.card-img-top {
    margin-top: 10px;
    height: 30%;
    width: 30%;
    align-self: center;
}
.btn-card {
    margin-top: 15px;
    text-align-last: center;
}
