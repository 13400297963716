/* .App {
    margin: 5px;
    background-color: #ececec;
} */

.btn {
    background-color: #a41e25;
    color: white;
    border: none;
}
.btn:hover {
    background-color: white;
    color: #a41e25;
    border: #a41e25;
    border-radius: 1px;
}

.line {
    border-bottom: 1px solid gray;
    opacity: 20%;
    margin-bottom: 10px;
    margin-top: 10px;
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media only screen and (max-width: 1200px) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
    body {
        padding: 10px;
        font-size: 12px;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
p {
    font-size: 20px;
}
a {
    color: white;
    font-size: 24px;
    font-weight: 700;
}
a:hover {
    background-color: white;
    color: #a41e25;
    border: #a41e25;
    border-radius: 1px;
}
ul,
li {
    font-size: 16px;
    text-indent: -20px; /* key property */
    margin-left: 20px; /* key property */
}
