.contact-form {
    display: inline-grid;
    margin: 5px;
}
.email-form {
    background: grey;
    text-align-last: center;
    /* margin: 7px; */
}
.contact-data {
    margin-bottom: 5px;
}
.container-xl {
    display: contents;
    padding: 5px;
}
.Call-Us {
    text-align: left;
}
