/* .cont-dis {
    display: flex;
    padding-bottom: 10px;
    margin: 10px;
}
li {
    padding: 10px;
}
.how-header {
    display: flex;
    justify-content: center;
    align-items: center;
} */

/* .container-xl {
    display: contents;
    padding: 5px;
} */
.how {
    text-align: left;
}
.how-col {
    align-self: center;
}
